<template>
  <div class="container">
    <div class="row text-center my-5" v-if="busy">
      <div class="col">
        <i class="fad fa-spinner fa-5x fa-spin text-primary"></i>
      </div>
    </div>
    <div v-else>
      <div class="row mb-3" v-if="plans.length > 0">
        <div class="col-md-8 mx-auto">
          <div class="card border-0 shadow-sm">
            <div class="card-body">
              <div class="row mb-3">
                <div class="col">
                  <h5>Health Plans</h5>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col">
                  <button v-for="plan in plans"
                          :key="plan.id"
                          @click="viewHealthPlan(plan)"
                          class="btn btn-outline-primary btn-lg mb-2 text-left
                          w-100 position-relative"
                  >
                    <span class="badge badge-danger badge-top-right" v-if="!plan.read_by_client">New</span>
                    <i class="fad fa-hand-holding-seedling text-primary mr-1"></i>
                    <small>{{ plan.name }}</small>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-3" v-if="plans.length === 0">
        <div class="col mx-auto">
          <div class="card border-0 shadow-sm">
            <div class="card-body py-4 text-center">
              <i class="fad fa-info-circle fa-5x text-primary mb-2"></i>
              <h3>No Health Plans found.</h3>
              <p>Health Plans sent by your practitioner will show here.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [],
  data() {
    return {
      busy: true,
      plans: [],
    };
  },
  computed: {
    user() {
      return this.$store.user;
    }
  },
  methods: {
    fetchHealthPlans() {
      this.$axios
              .get(process.env.VUE_APP_API_URL + "/client/health-planner")
              .then(({ data }) => {
                this.plans = data;
                this.busy = false;
              });
    },
    viewHealthPlan(plan) {
      this.$router.push("/health-planner/" + plan.id);
    },
  },
  mounted() {
    this.fetchHealthPlans();
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LL");
    }
  },
};
</script>

<style>
</style>
